import {ref, computed, getCurrentInstance, watch, onMounted} from 'vue';
import {defineStore} from 'pinia';
import {Product} from './types/Product';
import {persistedStateEncrypt} from './PersistedStateEncrypt.ts';
import axios from 'axios';
import {MijnAutoOnderdelenConfig} from './types/SiteSettings.ts';

type CartItem = Product & {amount: number};
// @ts-ignore
export const shoppingCartStore = defineStore(
    'shoppingCart',
    () => {
        const getUnix = () => Math.floor(new Date().getTime() / 1000);
        const lastUpdated = ref(getUnix());
        const siteSettingsStore = siteSettings();
        const items = ref<CartItem[]>([]);
        const requestDone = ref(false);
        const couponDiscount = ref<{couponCode: string; discount: number; percentage: number}>({
            couponCode: '',
            discount: 0,
            percentage: 0,
        });
        const deliveryDate = ref('');
        const addItem = (product: Product, n: number, setAmount: boolean = false) => {
            const round = (n: number, q: number) => {
                return Math.ceil(n / q) * q;
            };
            lastUpdated.value = getUnix();
            let foundItemIndex = items.value.findIndex((item) => item.id === product.id);

            if (foundItemIndex !== -1) {
                // Update the object in place
                items.value[foundItemIndex] = {
                    ...product,
                    amount: round(setAmount ? n : items.value[foundItemIndex].amount + n, product.orderQuantity),
                };
            } else {
                items.value.push({amount: round(n, product.orderQuantity), ...product});
            }
            if (!requestDone) return setRequestDone(true);
            axSetCart();
            couponDiscount.value = couponDiscount.value;
        };
        const removeItem = (product: Product) => {
            items.value.splice(
                items.value.findIndex((item) => item.id == product.id),
                1
            );
            lastUpdated.value = getUnix();

            if (items.value.length == 0) emptyCoupon();
            axSetCart();
            couponDiscount.value = couponDiscount.value;
        };

        const subTotalPrice = computed(() =>
            items.value.reduce((sum, el) => sum + Math.round(el.netPrice * el.amount * siteSettingsStore.btw * 10000) / 10000, 0)
        );
        const discountTotalPrice = computed(() => {
            return couponDiscount.value.percentage == 0
                ? couponDiscount.value.discount
                : subTotalPrice.value * (couponDiscount.value.percentage / 100);
        });

        const deliveryTotalPrice = computed(() => {
            if (items.value.length == 0) return 0;
            const cartItemsShipping = items.value
                .map((item) => {
                    const sortedAndFilteredShippingCosts = item.shippingCosts?.filter(
                        (ship) => ship.iso == siteSettingsStore.siteSettings.countryCode
                    );
                    // Only include non-empty filtered arrays

                    return sortedAndFilteredShippingCosts?.length > 0
                        ? {
                              costs: sortedAndFilteredShippingCosts[0].costs,
                              isAccu: item.localCategoryIds.some((cat) => cat == siteSettingsStore.deliveryLocalCategoryId),
                              amount: item.amount,
                          }
                        : null;
                })
                .filter((shippingCosts) => shippingCosts !== null)
                .sort((x, y) => y.costs - x.costs); // Remove null entries
            const cartItemsShippingB = cartItemsShipping.filter((ship) => !ship.isAccu)?.[0]?.costs ?? 0;

            const cartItemsShippingNotB = cartItemsShipping
                .filter((ship) => ship.isAccu)
                .reduce((sum, ship) => sum + ship.costs * ship.amount, 0);
            return cartItemsShippingNotB + cartItemsShippingB;
        });
        const totalPrice = computed(() => {
            let sum = Math.round(subTotalPrice.value * 100) / 100;
            if (siteSettingsStore.siteSettings.showShippingCostsInSummary) sum += deliveryTotalPrice.value;

            sum -= Math.round(discountTotalPrice.value * 100) / 100;
            sum += totalDeposit.value;
            return sum;
        });
        const totalDeposit = computed(() => items.value.reduce((sum, el) => sum + el.deposit * el.amount, 0) * siteSettingsStore.btw);
        const totalDiscount = computed(
            () => items.value.reduce((sum, el) => sum + el.netPrice * (el.discount / 100), 0) * siteSettingsStore.btw
        );
        const total = computed(() => items.value.reduce((sum, el) => sum + el.amount, 0));
        const count = computed(() => items.value.length);

        function filterProductFields(data: any): Product {
            return {
                id: data.id,
                articleNr: data.articleNr,
                productId: data.productId,
                localBrand: data.localBrand,
                depotId: data.depotId,
                catalogNr: data.catalogNr,
                localBrandId: data.localBrandId,
                supplierCode: data.supplierCode,
                inStock: data.inStock,
                description: data.description,
                title: data.title,
                slug: data.slug,
                orderQuantity: Number(data.orderQuantity),
                discount: Number(data.discount),
                netPrice: Number(data.netPrice),
                shippingCosts: data.shippingCosts,
                shippingCostsLetter: data.shippingCostsLetter,
                deposit: Number(data.deposit),
                generic: data.generic,
                defaultCategoryImage: data.defaultCategoryImage,
                images: data.images,
                shoppingImage: data.shoppingImage ?? data.images[0] ?? '',
                href: data.href,
                grossPrice: Number(data.grossPrice),
                localCategoryIds: data.localCategoryIds,
                priceChoice: data.priceChoice ?? 2,
                car: data.car ?? undefined,
                typeCode: data.vehicleCode ?? undefined,
                fastDeliveryPrices: data.fastDeliveryPrices,
            };
        }

        const axSetCart = () => {
            setRequestDone(false);
            const cartItems = items.value.map((item) => ({
                localBrandId: item.localBrandId,
                catalogNr: item.catalogNr,
                orderQuantity: item.amount,
                href: item.href,
                car: item.car,
                typeCode: item.typeCode,
                ...(item.priceChoice !== undefined && {
                    supplierCode: item.supplierCode,
                    priceChoice: item.priceChoice,
                    depotId: item.depotId,
                }),
            }));

            axios.post('/winkelwagen/store', {items: cartItems}).then((response) => {
                deliveryDate.value = response.data.message.DeliveryDate;
                setRequestDone(true);
            });
        };
        const setRequestDone = (state: boolean) => {
            requestDone.value = state;
        };

        onMounted(() => {
            if (items.value.length > 0 && Math.abs(lastUpdated.value - getUnix()) / 60 / 60 >= 1) {
                axSetCart();
                axios
                    .post('/winkelwagen/update', {
                        items: items.value.map((item) => item.id),
                    })
                    .then((response) => {
                        response.data.map(
                            (resItem: {
                                inStock: boolean;
                                productId;
                                prices: {
                                    deposit: number;
                                    depotId: number;
                                    discount: number;
                                    dropShipment: boolean;
                                    grossPrice: number;
                                    netPrice: number;
                                    orderQuantity: number;
                                    prio: number;
                                    purchasePrice: number;
                                    supplierCode: number;
                                    supplierId: number;
                                    supplierNr: string;
                                    updatedAt: string;
                                }[];
                                netPrice;
                                grossPrice;
                                discount;
                            }) => {
                                let foundItem = items.value.find((item) => item.id == resItem.productId);
                                if (!resItem.inStock) {
                                    removeItem(foundItem);
                                    return;
                                }
                                // match on depot
                                let foundPriceChoice =
                                    foundItem.fastDeliveryPrices != null
                                        ? Object.values(foundItem.fastDeliveryPrices)?.filter((fdp) => {
                                              return resItem.prices.some((item) => item.prio == fdp.prio);
                                          })
                                        : [];

                                if (foundItem.priceChoice === 1 && foundPriceChoice.length > 0) {
                                    const updatedPrice =
                                        foundPriceChoice.find(
                                            (fpc) => fpc.depotId === foundItem.depotId && fpc.supplierCode === foundItem.supplierCode
                                        ) ?? foundPriceChoice[0];
                                    foundItem.netPrice = Number(updatedPrice.netPrice);
                                    foundItem.grossPrice = Number(updatedPrice.grossPrice);
                                    foundItem.discount = Number(updatedPrice.discount);
                                    foundItem.depotId = updatedPrice.depotId;
                                    foundItem.supplierCode = updatedPrice.supplierCode;
                                    return;
                                }

                                //after stil not found use default
                                foundItem.netPrice = resItem.netPrice;
                                foundItem.grossPrice = resItem.grossPrice;
                                foundItem.discount = resItem.discount;
                                foundItem.depotId = null;
                                foundItem.supplierCode = null;
                                foundItem.priceChoice = 2;
                            }
                        );
                        lastUpdated.value = getUnix();
                    });
                if (couponDiscount.value.couponCode !== '') {
                    // @ts-ignore
                    axios.post(window.route.shoppingcart.validatediscountcode, {
                        discountCode: couponDiscount.value.couponCode,
                    });
                }
            }
        });

        const emptyCart = () => {
            items.value = <CartItem[]>[];
            axSetCart();
            lastUpdated.value = getUnix();
        };
        const emptyCoupon = () => {
            couponDiscount.value = {couponCode: '', discount: 0, percentage: 0};
        };
        return {
            items,
            addItem,
            removeItem,
            filterProductFields,
            total,
            totalPrice,
            count,
            totalDiscount,
            subTotalPrice,
            discountTotalPrice,
            deliveryTotalPrice,
            requestDone,
            lastUpdated,
            couponDiscount,
            axSetCart,
            emptyCart,
            deliveryDate,
            emptyCoupon,
            setRequestDone,
            totalDeposit,
        };
    },
    {
        // @ts-ignore is valid because of persist pinia lib
        persist: {
            storage: persistedStateEncrypt,
        },
    }
);

export const siteSettings = defineStore(
    'siteSettings',
    () => {
        const siteId = ref(1);
        const siteSettings = ref<MijnAutoOnderdelenConfig>({
            Afzendernaam: '',
            BTWpercentage: 21,
            BannerActief: false,
            BestellingMail: '',
            ContactMail: '',
            Grensbedrag: 0,
            KortingBijGrensbedrag: false,
            OnderdeelaanvraagMail: '',
            ProductenPerPagina: 0,
            baseUrl: '',
            copy_aanmeldingmail: '',
            countryCode: 528,
            homeBannerId: 0,
            licensePlateServiceOnline: false,
            locale: '',
            logo: '',
            name: '',
            showAdministrativeCostsInSummary: false,
            showShippingCostsInSummary: false,
            taal: 'nl_NL',
            widgetBehaviour: '',
        });
        const isResolved = ref(true);
        const siteName = ref('');
        const minOrderAmountForDiscount = ref(0);
        const deliveryLocalCategoryId = ref(1);
        const isChannel = computed(() => {
            switch (siteId.value) {
                case 1:
                case 2:
                    return 'mao';
                case 3:
                case 4:
                    return 'cpd';
                case 5:
                    return 'ate';
            }
        });
        const setSettings = (settings: {
            siteId: number;
            siteSettings: MijnAutoOnderdelenConfig;
            isResolved: boolean;
            siteName: string;
            minOrderAmountForDiscount: number;
        }) => {
            siteId.value = settings.siteId;
            siteSettings.value = {
                Afzendernaam: settings.siteSettings.Afzendernaam,
                BTWpercentage: settings.siteSettings.BTWpercentage,
                BannerActief: !!settings.siteSettings.BannerActief,
                BestellingMail: settings.siteSettings.BestellingMail,
                ContactMail: settings.siteSettings.ContactMail,
                Grensbedrag: settings.siteSettings.Grensbedrag,
                KortingBijGrensbedrag: !!settings.siteSettings.KortingBijGrensbedrag,
                OnderdeelaanvraagMail: settings.siteSettings.OnderdeelaanvraagMail,
                ProductenPerPagina: settings.siteSettings.ProductenPerPagina,
                baseUrl: settings.siteSettings.baseUrl,
                copy_aanmeldingmail: settings.siteSettings.copy_aanmeldingmail,
                countryCode: settings.siteSettings.countryCode,
                homeBannerId: settings.siteSettings.homeBannerId,
                licensePlateServiceOnline: !!settings.siteSettings.licensePlateServiceOnline,
                locale: settings.siteSettings.locale,
                logo: settings.siteSettings.logo,
                name: settings.siteSettings.name,
                showAdministrativeCostsInSummary: !!settings.siteSettings.showAdministrativeCostsInSummary,
                showShippingCostsInSummary: !!settings.siteSettings.showShippingCostsInSummary,
                taal: settings.siteSettings.taal,
                widgetBehaviour: settings.siteSettings.widgetBehaviour,
            };
            isResolved.value = settings.isResolved;
            siteName.value = settings.siteName;
            minOrderAmountForDiscount.value = settings.minOrderAmountForDiscount;
        };

        const btw = computed(() => siteSettings.value.BTWpercentage / 100 + 1);

        return {
            siteSettings,
            siteId,
            isResolved,
            siteName,
            minOrderAmountForDiscount,
            btw,
            setSettings,
            deliveryLocalCategoryId,
            isChannel,
        };
    },
    {
        // @ts-ignore is valid because of persist pinia lib
        persist: {
            storage: persistedStateEncrypt,
        },
    }
);

export const eventsNotif = defineStore('eventsNotif', () => {
    const shoppingCartSummaryAlert = ref(false);
    return {shoppingCartSummaryAlert};
});
